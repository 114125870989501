import Repository from "./Repository";
const LEADS = "/sponsors/2066/leads";
const LEADS_BENL = "/sponsors/2308/leads";

import qs from "qs";

export default {
  postLead(payload) {
    return Repository.post(`${LEADS}`, qs.stringify(payload));
  },
  postLeadBadkamer(payload) {
    return Repository.post(`${LEADS_BENL}`, qs.stringify(payload));
  },
};
