import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Home_badkamers from "../views/Home_badkamers.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/badkamers",
    name: "Home Badkamers",
    component: Home_badkamers,
  },

  {
    path: "/bedankt",
    name: "Bedankt",

    component: () => import("../views/Bedankt.vue"),
  },
  {
    path: "/bedanktBadKamers",
    name: "Bedankt-badkamers",

    component: () => import("../views/Bedankt_badkamers.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
