<template>
  <div>
    <img
      src="../../assets/mbl-header.png"
      class="d-block d-md-none w-100"
      alt=""
    />
    <div class="d-none d-md-block">
      <b-container fluid="xl"> <Navbar_badkamers /></b-container>
      <div class="hero">
        <!-- <img src="../../assets/backgroundHero.jpg" class="hero__back" /> -->
        <div class="position-absolute d-block d-md-none hero__bookimg">
          <img
            src="../../assets/mobilaeCatalog.png"
            alt="Mobilae Brochure"
            class="w-100 hero__brochure"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar_badkamers from "./Navbar_badkamers.vue";

export default {
  name: "Header_badkamers",
  components: {
    Navbar_badkamers,
  },
};
</script>

<style lang="scss" scoped>
.hero {
  background-image: url("../../assets/backgroundHero.jpg");
  height: 400px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 991px) {
    height: 250px;
  }
}

.center {
  height: 200px;
  width: 200px;
  background-color: #ff4800;
  border-radius: 50%;
  position: absolute;
  top: 32%;
  left: 26%;
  color: white;
  text-align: center;
  font-size: 25px;
}
.centerArrow {
  margin-left: 63px;
  user-select: none;
}
@media (max-width: 1280px) {
  .center {
    height: 200px;
    width: 200px;
    background-color: #ff4800;
    border-radius: 50%;
    position: absolute;
    top: 34%;
    left: 26%;
    color: white;
    text-align: center;
    font-size: 25px;
  }
  .centerArrow {
    margin-left: 63px;
    user-select: none;
    pointer-events: none;
  }
}

@media (max-width: 915px) {
  .center {
    height: 200px;
    width: 200px;
    background-color: #ff4800;
    border-radius: 50%;
    position: absolute;
    top: 10%;
    left: 20%;
    color: white;
    text-align: center;
    font-size: 25px;
  }
}

@media (max-width: 820px) {
  .center {
    height: 160px;
    width: 160px;
    background-color: #ff4800;
    border-radius: 50%;
    position: absolute;
    top: 11%;
    left: 26%;
    color: white;
    text-align: center;
    font-size: 21px;
  }
  .centerArrow {
    width: 57px;
    margin-left: 53px;
    user-select: none;
  }
}
@media (max-width: 420px) {
  .center {
    height: 80px;
    width: 80px;
    background-color: #ff4800;
    border-radius: 50%;
    position: absolute;
    top: 8%;
    left: 23%;
    color: white;
    text-align: center;
    font-size: x-small;
  }
  .centerArrow {
    width: 35px;
    margin-left: 25px;
    user-select: none;
  }
}
.textAlign {
  color: white;
  margin-top: 14%;
  user-select: none;
}

@media (max-width: 575px) {
  .hero__bookimg {
    top: 14px !important;
    right: 0px !important;
    overflow: hidden;
  }
  .hero__brochure {
    transform: rotate(8deg);
    height: 140px !important;
    right: -58px;
    top: 10px;
    position: relative;
  }
  .hero__back {
    height: 120px !important;
    width: 100% !important;
  }
  .nav {
    height: 30px !important;
  }
}
</style>
