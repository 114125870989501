<template>
  <div class="formSection" id="form-section">
    <b-container fluid="xl">
      <div class="px-md-2">
        <b-row cols-md="2" cols="1">
          <b-col class="text-start pe-lg-5 pe-1 main-details"
            ><div>
              <img
                src="../../assets/mobilaeCatalog.png"
                class="mt-md-n5 book-img mx-auto text-center d-none d-md-block"
              />

              <h1 class="display-5 fw-stronger formSection__title">
                Maak uw badkamer weer veilig!
              </h1>

              <div>
                <p
                  class="l-height formSection__desc paragraph d-none d-lg-block"
                >
                  Maak veilig en comfortabel gebruik van uw badkamer. Kies voor
                  een lage instap, een antislipvloer of andere extra’s als een
                  ingebouwde handgreep, badlift of douchezitje. In onze gratis
                  brochure kunt u op uw gemak alles lezen over badkamers. Door
                  de brochure krijgt u een goed beeld van de mogelijkheden en
                  modellen, service en financiering.
                </p>
              </div>

              <div class="row d-flex d-lg-none">
                <p class="formSection__desc">
                  Maak veilig en comfortabel gebruik van uw badkamer. Kies voor
                  een lage instap, een antislipvloer of andere extra’s als een
                  ingebouwde handgreep, badlift of douchezitje. In onze gratis
                  brochure kunt u op uw gemak alles lezen over badkamers.
                </p>
                <div class="col-8">
                  <p class="formSection__desc mb-1">
                    Door de brochure krijgt u een goed beeld van de
                    mogelijkheden en modellen,service en financiering.
                  </p>
                </div>
                <div class="col-4">
                  <img
                    src="../../assets/desktop_sticker.png"
                    class="d-block d-md-none mbl-image"
                    height="100px"
                    width="auto"
                  />
                </div>
              </div>

              <h4 class="fw-stronger formSection__tipsHeader d-none d-md-block">
                Vraag onze brochure aan en ontdek:
              </h4>

              <ol class="d-none d-md-block">
                <li class="l-height mb-0 formSection__tips">
                  Tips en adviezen
                </li>
                <li class="l-height mb-0 formSection__tips">
                  Grootste assortiment in merken en modellen
                </li>
                <li class="l-height mb-0 formSection__tips">
                  Keuze in iedere prijsklasse
                </li>
              </ol>
            </div></b-col
          >
          <b-col class="form-col position-relative">
            <div>
              <img
                src="../../assets/desktop_sticker.png"
                class="position-absolute desktop-sticker d-none d-lg-block"
                height="150px"
                width="auto"
              />
            </div>
            <b-card class="text-white mb-3">
              <input
                type="checkbox"
                class="hidden-checkbox"
                v-model="bootCheck"
              />
              <div>
                <h3 class="formSection__form__header">
                  <span class="fw-bold"
                    >Bad eruit, douche erin binnen 1 dag?</span
                  >
                </h3>
                <h4 class="formSection__form__header">
                  Vraag nu de <b> gratis</b> en <b>vrijblijvende</b> badkamer
                  brochure aan!
                </h4>
                <b-form
                  @submit.prevent="onSubmit"
                  v-if="show"
                  class="text-start"
                >
                  <b-form-group
                    id="input-group-1"
                    label="Aanhef:"
                    label-for="input-1"
                  >
                    <b-form-select
                      id="input-1"
                      v-model="form.gender"
                      :options="gender"
                      required
                      class="form-control"
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group
                    id="input-group-2"
                    label="Voornaam:"
                    label-for="input-2"
                  >
                    <b-form-input
                      id="input-2"
                      :state="fNameValidation"
                      @input="handleFieldInput('firstname')"
                      v-model="form.firstname"
                      placeholder="Bv. Lore"
                      required
                    ></b-form-input>
                    <b-form-invalid-feedback :state="fNameValidation">
                      <!-- Alleen letters zijn toegestaan. -->
                      voornaam niet correct
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                    id="input-group-3"
                    label="Achternaam:"
                    label-for="input-3"
                  >
                    <b-form-input
                      id="input-3"
                      :state="lNameValidation"
                      v-model="form.lastname"
                      @input="handleFieldInput('lastname')"
                      placeholder="Bv. Peeters"
                      required
                    ></b-form-input>
                    <b-form-invalid-feedback :state="lNameValidation">
                      <!-- Alleen letters zijn toegestaan. -->
                      Achternaam niet correct
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                    id="input-group-4"
                    label="Telefoonnummer:"
                    label-for="input-4"
                  >
                    <b-form-input
                      id="input-4"
                      :state="phoneValidation"
                      v-model="form.phone_number"
                      @input="handleFieldInput('phone_number')"
                      type="tel"
                      placeholder="Bv. 0471123456"
                      maxlength="10"
                      required
                    ></b-form-input>
                    <b-form-invalid-feedback :state="phoneValidation">
                      <!-- Telefoonnummer moet 10 getallen bevatten. -->
                      Telefoonnummer niet correct
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                    id="input-group-5"
                    label="E-mail:"
                    label-for="input-5"
                  >
                    <b-form-input
                      :state="emailValidation"
                      id="input-5"
                      v-model="form.email"
                      @input="handleFieldInput('email')"
                      type="email"
                      placeholder="Bv. lore.peeters@gmail.com"
                      required
                    ></b-form-input>
                    <b-form-invalid-feedback :state="emailValidation">
                      <!-- Telefoonnummer moet 10 getallen bevatten. -->
                      E-mailaders niet correct
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group
                    id="input-group-6"
                    v-slot="{ ariaDescribedby }"
                    class="py-3"
                  >
                    <b-form-checkbox-group
                      v-model="form.answers"
                      id="checkboxes-6"
                      :aria-describedby="ariaDescribedby"
                      required
                    >
                      <b-form-checkbox
                        value="4459"
                        required
                        class="d-flex align-items-baseline"
                      >
                        <h6 class="ms-3 formSection__form__checkbox">
                          Ik ga akkoord met de Privacy Policy en begrijp dat
                          Mobilae contact met mij kan opnemen. Ik kan mijn
                          toestemming op elk moment intrekken.
                        </h6>
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>

                  <b-button
                    type="submit"
                    variant="primary"
                    class="s-btn btn-lg fw-bold formSection__form__btn"
                    :disabled="getLoader"
                    ><b-spinner v-if="getLoader" class="text-center" /><span
                      v-else
                      >BROCHURE AANVRAGEN >></span
                    >
                  </b-button>
                </b-form>
              </div>
            </b-card></b-col
          >
        </b-row>
      </div>
    </b-container>
    <div class="hero bg-color py-4 py-md-5 mt-4">
      <b-container fluid="xl">
        <div class="px-xl-5 px-md-2 mx-lg-2 p-3">
          <b-row cols-md="2" cols="1" class="align-items-center">
            <b-col class="d-block d-md-none">
              <div>
                <img
                  src="../../assets/afspraak.png"
                  class="w-100 rounded mb-3"
                />
              </div>
            </b-col>
            <b-col class="text-start"
              ><div>
                <h1 class="fw-stronger formSection__form__whyme">
                  Waarom Mobilae?
                </h1>

                <p class="l-height formSection__form__whymeDesc">
                  Elke badkamer is anders. En ieders wensen zijn anders. Daarom
                  geven wij u graag een gratis persoonlijk advies bij u thuis
                  met maattekening. Onze adviseur bespreekt uw wensen en zet de
                  mogelijkheden op een rij. Dit persoonlijke advies is
                  vrijblijvend, objectief, deskundig en 100% afgestemd op uw
                  wensen en uw badkamer. Daarnaast hebben wij een grote keuze in
                  elke prijsklasse en is financiering ook mogelijk.
                </p>
              </div>
            </b-col>
            <b-col class="d-none d-md-block">
              <div>
                <img src="../../assets/afspraak.png" class="w-100 rounded" />
              </div>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "FormSection_badkamers",

  data() {
    return {
      bootCheck: false,
      validationCheck: false,
      emailErr: true,
      temp_answers: {
        answers: [],
      },
      form: {
        language: "nl_BE",
        publisher_id: "",
        site_subid: "",
        site_custom_url: "https://mobilae.web.app",
        site_custom_name: "mobilae_badkamers_benl",
        ip: "91.180.156.50",
        optin_timestamp: "2022-05-12 14:28:25",
        date_of_birth: "1975-04-08",
        answers: [],
        gender: "null",
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
      },
      gender: [
        { text: "Aanhef", value: null, disabled: true },
        { text: "Dhr.", value: "male", disabled: false },
        { text: "Mevrouw.", value: "female", disabled: false },
      ],
      show: true,
    };
  },

  methods: {
    ...mapActions(["postLeadBenl"]),
    handleFieldInput(fieldName) {
      console.log("phone number changed", fieldName);
      if (this.getErrorResponses && !this.getErrorResponses[fieldName]) {
        console.log("phone number changed2", fieldName);
        this.$store.commit("setError", {
          ...this.getErrorResponses,
          [fieldName]: true,
        });
      }
    },
    resetForm() {
      this.form = {
        language: "nl_BE",
        publisher_id: "1",
        site_custom_url: "https://mobilae.web.app",
        site_custom_name: "mobilae_badkamers_benl",
        ip: "91.180.156.50",
        optin_timestamp: "2022-05-12 14:28:25",
        date_of_birth: "1975-04-08",
        answers: [4459],
        gender: "null",
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
      };
    },
    async onSubmit() {
      console.log(" ...this.form", this.form);
      if (this.validationCheck) {
        let resp = await this.postLeadBenl({
          ...this.form,
          ...this.bootCheck,
          site_subid: this.$route.query.oa_id,
          publisher_id: this.$route.query.site,
        });
        if (resp.success == true) {
          this.resetForm();
          this.$router.push("bedanktBadKamers");
        }
        console.log(resp);
      }
    },
  },
  computed: {
    ...mapGetters(["getLoader", "getErrorResponses"]),
    fNameValidation() {
      if (this.form.firstname == "" && !this.getErrorResponses.firstname) {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^([a-zA-Z ]){1,30}$/;
      if (this.getErrorResponses.firstname == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.firstname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    lNameValidation() {
      if (this.form.lastname == "" && !this.getErrorResponses.lastname) {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^([a-zA-Z ]){1,30}$/;
      if (this.getErrorResponses.lastname == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.lastname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    emailValidation() {
      // if (this.form.email == "") {
      //   this.validationCheck = false;
      //   return null;
      // }
      // if (
      //   /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email)
      // ) {
      //   this.validationCheck = true;
      //   return true;
      // } else {
      //   this.validationCheck = false;
      //   return false;
      // }
      if (this.form.email == "" && !this.getErrorResponses.email) {
        this.validationCheck = false;
        return null;
      }
      if (this.getErrorResponses.email == false) {
        this.validationCheck = false;
        return false;
      } else if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email)
      ) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    phoneValidation() {
      // if (this.form.phone_number == "") {
      //   this.validationCheck = false;
      //   return null;
      // }
      // let phoneRegex = /^([0])(\d{9})$/;
      // if (this.form.phone_number == "0") {
      //   return true;
      // } else if (this.form.phone_number.match(phoneRegex)) {
      //   this.validationCheck = true;
      //   return true;
      // } else {
      //   this.validationCheck = false;
      //   return false;
      // }
      if (
        this.form.phone_number == "" &&
        !this.getErrorResponses.phone_number
      ) {
        this.validationCheck = false;
        return null;
      }

      let phoneRegex = /^0\d{9}$/;
      if (this.getErrorResponses.phone_number == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.phone_number == "0") {
        this.validationCheck = false;
        return true;
      } else if (this.form.phone_number.match(phoneRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
$enable-negative-margins: true;
.hidden-checkbox {
  opacity: 0;
}
.custom-select {
  appearance: revert !important;
}
.form-control {
  padding: 0.75rem 0.75rem !important;
}
.s-btn {
  padding: 1rem !important;
  width: 100%;
  background-color: #ff5a00 !important;
  border: 0 !important;
  border-radius: 0px !important;
  @media (min-width: 767px) and (max-width: 990px) {
    padding: 1rem !important;
  }
}
.book-img {
  height: 450px;
  width: 450px;
  // width: 100%;
  // z-index: 1 !important;
  // position: relative;
  // @media (max-width: 767.9px) {
  //   width: 50% !important;
  // }
}
.card {
  margin: 50px 0;
  border-radius: 10px !important;
  @media (max-width: 575px) {
    margin: 0px 0;
    border-radius: 10px !important;
  }
}
.card-body {
  background-color: #50a89e !important;
  color: white;
  padding: 50px !important;
  border-radius: 7px;
  @media (max-width: 990px) {
    padding: 30px 15px !important;
  }
}
.opacity {
  opacity: 0.6;
}
label {
  padding: 7px 0;
}
@media (min-width: 768px) {
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
}
.bg-color {
  background-color: #b9e6ff !important;
}
.l-height {
  line-height: 1.9 !important;
}
.desktop-sticker {
  // margin-left: 25% !important;
  // margin-left: 10%;
  right: 10%;
  margin-top: -52px;
  z-index: 1;
  // @media (max-width: 1440px) {
  //   margin-left: 30% !important;
  //   margin-top: -60px !important;
  // }
  // @media (max-width: 992px) {
  //   margin-left: 28% !important;
  //   margin-top: -80px !important;
  //   z-index: 1 !important;
  //   height: 150px !important;
  //   width: auto !important;
  // }
  // @media (max-width: 768px) {
  //   margin-left: 25% !important;
  // }

  // @media (max-width: 575px) {
  //   margin-left: 64% !important;
  //   margin-top: -74px !important;
  //   z-index: 1 !important;
  //   height: 150px !important;
  //   width: auto !important;
  // }
  // @media (max-width: 425px) {
  //   margin-left: 58% !important;
  // }

  // @media (max-width: 375px) {
  //   margin-left: 55% !important;
  // }
  // @media (max-width: 320px) {
  //   margin-left: 50% !important;
  // }
}

.formSection {
  &__title {
    @media (max-width: 575px) {
      font-size: 1.3rem !important;
      margin-top: 10px !important;
    }
  }
  &__desc {
    @media (max-width: 575px) {
      font-size: 0.9rem !important;
      line-height: 1.6 !important;
    }
  }

  &__form {
    @media (max-width: 575px) {
      &__header {
        font-size: 1.5rem !important;
      }
      &__checkbox {
        font-size: 13px !important;
      }
      &__btn {
        font-size: 16px !important;
      }
      &__whyme {
        font-size: 1.3rem !important;
      }
      &__whymeDesc {
        font-size: 0.9rem !important;
        line-height: 1.6 !important;
      }
    }
  }
}

.paragraph {
  @media (max-width: 575px) {
    text-align: justify;
  }
}
.image {
  @media (max-width: 575px) {
    float: right;
    margin: 0 10px 10px 0;
  }
}
.mbl-image {
  width: 150px;
  height: 161px;
  position: absolute;
  z-index: 1;
  margin-top: -30px;
  @media (max-width: 667.99px) {
    width: 130px;
    height: 141px;
    margin-top: -15px;
  }
  @media (max-width: 575.99px) {
    // margin-top: 10px;
    width: 91px;
    height: 100px;
    margin-top: -20px;
  }
  @media (max-width: 518.99px) {
    width: 101px;
    height: 110px;
    margin-top: -10px;
  }
  @media (max-width: 400.99px) {
    width: 81px;
    height: 90px;
    margin-top: 2%;

    right: 10%;
  }

  @media (max-width: 342.99px) {
    width: 81px;
    height: 90px;

    margin-top: 15%;
  }

  // @media (max-width: 575px) {
  //   position: absolute;
  //   top: 66%;
  //   z-index: 1;
  //   left: 74%;
  // }
  // @media (max-width: 520px) {
  //   position: absolute;
  //   top: 68%;
  //   z-index: 1;
  //   left: 74%;
  // }
  // @media (max-width: 500px) {
  //   position: absolute;
  //   top: 66%;
  //   z-index: 1;
  //   left: 74%;
  // }
  // @media (max-width: 480px) {
  //   position: absolute;
  //   top: 64%;
  //   z-index: 1;
  //   left: 74%;
  // }
  // @media (max-width: 450px) {
  //   position: absolute;
  //   top: 62%;
  //   z-index: 1;
  //   left: 74%;
  // }
  // @media (max-width: 400px) {
  //   position: absolute;
  //   top: 63%;
  //   z-index: 1;
  //   left: 74%;
  // }
  // @media (max-width: 375px) {
  //   position: absolute;
  //   top: 62%;
  //   z-index: 1;
  //   left: 71%;
  // }
  // @media (max-width: 370px) {
  //   position: absolute;
  //   top: 61%;
  //   z-index: 1;
  //   left: 71%;
  // }
  // @media (max-width: 360px) {
  //   position: absolute;
  //   top: 67%;
  //   z-index: 1;
  //   left: 71%;
  // }
  // @media (max-width: 350px) {
  //   position: absolute;
  //   top: 71%;
  //   z-index: 1;
  //   left: 71%;
  // }
  // @media (max-width: 340px) {
  //   position: absolute;
  //   top: 70%;
  //   z-index: 1;
  //   left: 70%;
  // }
  // @media (max-width: 320.99px) {
  //   position: absolute;
  //   top: 68%;
  //   z-index: 1;
  //   left: 65%;
  // }
}
</style>
