import { render, staticRenderFns } from "./Header_badkamers.vue?vue&type=template&id=a3e997aa&scoped=true&"
import script from "./Header_badkamers.vue?vue&type=script&lang=js&"
export * from "./Header_badkamers.vue?vue&type=script&lang=js&"
import style0 from "./Header_badkamers.vue?vue&type=style&index=0&id=a3e997aa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3e997aa",
  null
  
)

export default component.exports